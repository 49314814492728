import React, { Component } from "react"
import { GlobalContainer, GlobalSection, Anchor, DisabledAnchor } from "../../components/GlobalStyle"
import styled from "styled-components"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import headerImage from "../../images/slider/slide-2.jpg"
import Map from "../../components/Map"
import SectionContact from "../../components/sectionContact"
import RoomsGallery from "../../components/RoomsGallery"
import duze1 from '../../images/gallery/duze-1.jpeg'
import duze2 from '../../images/gallery/duze-2.jpeg'
import male1 from '../../images/gallery/male-1.jpeg'
import male2 from '../../images/gallery/male-2.jpeg'
import Helmet from "react-helmet"
import { withTranslation } from 'react-i18next';

const StyledBody = styled.div`
  background-color: #F4F4F4;
`
const StyledOfferContainer = styled(GlobalContainer)`
  max-width: 1040px;
  margin-bottom: 200px;
  p {
  margin: 20px 0;
  }
`

const StyledFAQContainer = styled(GlobalContainer)`
  padding-top: 100px;
  max-width: 1040px;
  margin-bottom: 200px;
  a {
    font-weight: bold;
    color: var(--main);
  }
  p {
    margin: 20px 0;
  }
  ul {
    li {
      display: flex;
      &:before {
        content: '';
        display: block;
        height: 4px;
        width: 16px;
        background-color: var(--main);
        margin-right: 10px;
        position: relative;
        top: 15px;
      }
    }
    div {
      flex: 1;
      p {
        margin-top: 0;
      }
    }
  }
`
const StyledPageHeaderDetails = styled.div`
  background-color: #fff;
  padding: 20px;
  display: flex;
  box-shadow: 0 2px 4px 0 rgba(12,0,46,0.04);
  position: relative;
  transform: translateY(-50%);
  @media(max-width: 800px) {
    flex-direction: column;
    transform: translateY(-50px);
  }
  & > div {
    flex: 1;
    text-align: center;
    padding: 30px 10px;
    font-size: 1.4em;
    line-height: 1.1em;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: calc(50% - 20px);
      right: 0;
      display: block;
      height: 40px;
      width: 1px;
      background-color: var(--main);
      @media(max-width: 800px) {
        display: none;
      }
    }
    &:last-of-type:after{
      display: none; 
    }
    span {
      text-transform: uppercase;
      font-weight: bold;
      opacity: .5;
      display: block;
      font-size: .6em;
    }
  }
`



const StyledDivider = styled.div`
  border-bottom: 2px dashed rgba(0, 0, 0, 0.1);
  margin: 40px 0;
`

const StyledHourInfo = styled.div`
  text-align: center;
  margin: 20px 0;
  font-size: .9em;
  padding: 0 40px;
  p {
    opacity: .6;
    position: relative;
    top: -30px;
  }
`

export const biuroDuze = [
  {
    src: duze1,
    width: 12,
    height: 6.7
  },
  {
    src: duze2,
    width: 12,
    height: 6.7
  },
];

export const biuroMale = [
  {
    src: male1,
    width: 12,
    height: 6.7
  },
  {
    src: male2,
    width: 12,
    height: 6.7
  },
];

class OfficesOfferPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: "1 Godzina",
      type: "sale-wideokonferencyjne",
      desk: "biurko dedykowane",
    }
  }

  render() {
    return (
      <StyledBody>
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "Gdzie znajdę sale szkoleniowe w trójmieście?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Jeśli szukasz sal szkoleniowych w Trójmieście, znajdziesz je w Leśnahub na terenie Sopotu. Sale szkoleniowe szeroki zakres cen i rozmiarów, aby dopasować się do każdego budżetu i potrzeb. Dopasuj sale do odpowiedniego spotkania biznesowego."
                  }
                },{
                  "@type": "Question",
                  "name": "Ile kosztuje wynajem sali szkoleniowej?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Koszt wynajęcia sali szkoleniowej zależy od wielkości sali, na ile godzin jej potrzebujesz. Leśna Hub stwarza możliwość zorganizowania szkoleń i konferencji w samym centrum Trójmiasta. Dzięki doskonałej lokalizacji dojazd staje się bezproblemowy praktycznie każdym środkiem transportu. Ceny zaczynają się już od 69 PLN/H"
                  }
                }]
              }
          `}
          </script>
        </Helmet>
        <SEO
          title="Biura na wynajem"
          description="Nasza przestrzeń oferuje dużą salę konferencyjną idealną dla cyklicznych meetupoów i konferancji, oraz mniejsze sale wideokonferencyjne na spotkania z Twoimi klientami"
        />
        <PageHeader image={headerImage}>
          <h1 data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">{this.props.t('offerOfficeRent.header')}</h1>
        </PageHeader>
        <StyledOfferContainer>
          <StyledPageHeaderDetails data-sal="fade" data-sal-delay="500" data-sal-duration="400">
            <div data-sal="slide-up" data-sal-delay="600" data-sal-duration="400">
              <span>{this.props.t('offerOfficeRent.office')}</span>
              2
            </div>
            <div data-sal="slide-up" data-sal-delay="650" data-sal-duration="400">
              <span>{this.props.t('offerOfficeRent.area')}</span>
              30 - 105m2
            </div>
            <div data-sal="slide-up" data-sal-delay="700" data-sal-duration="400">
              <span>{this.props.t('offerOfficeRent.location')}</span>
              Sopot
            </div>
          </StyledPageHeaderDetails>

          <section>
            <div>
              <h3>{this.props.t('offerOfficeRent.title1')}</h3>
              <p>
							{this.props.t('offerOfficeRent.text1')}
              </p>

              <br/>
              <RoomsGallery photos={biuroDuze} />

              <StyledHourInfo>
                <DisabledAnchor href=""  target="_blank" rel="noopener">	{this.props.t('common.unavailable')}</DisabledAnchor>
              </StyledHourInfo>

              <StyledDivider/>


              <h3>{this.props.t('offerOfficeRent.title2')}</h3>
              <p>
							{this.props.t('offerOfficeRent.text2')}
              </p>

              <br/>
              <RoomsGallery photos={biuroMale}/>

              <StyledHourInfo>
                <Anchor href="https://invilla.pl/oferty-nieruchomosci/przestrzen-biurowa-w-nowoczesnym-budynku-lesna-hub/" target="_blank" rel="noopener">	{this.props.t('common.details')}</Anchor>
              </StyledHourInfo>

            </div>
          </section>


        </StyledOfferContainer>
        <Map/>

        <SectionContact/>
      </StyledBody>
    )
  }
}

export default withTranslation()(OfficesOfferPage)
