import React, { Component } from "react"
import { GlobalContainer, GlobalSection, Button } from "./GlobalStyle"
import styled from "styled-components"
import { withTranslation } from 'react-i18next';

const StyledContactSection = styled.div`
  text-align: center;
  span {
    text-transform: uppercase;
    color: var(--main);
  }
  h3 {
    font-size: 48px;
    @media (max-width: 1000px) {
      font-size: 32px;
    }
    @media (max-width: 650px) {
      font-size: 28px;
    }
  }
  p {
    margin-bottom: 40px;
    @media (max-width: 1000px) {
      font-size: 16px;
    }
    @media (max-width: 600px) {
      margin-top: 40px;
    }
  }
`

class SectionContact extends Component {
	
  render() {
    return (
      <GlobalSection>
        <GlobalContainer>
          <section>
            <StyledContactSection>
              <span data-sal="slide-up" data-sal-delay="500" data-sal-duration="400">{this.props.t('nav.contact')}</span>
              <h3 data-sal="slide-up" data-sal-delay="300" data-sal-duration="400">{this.props.t('contact.title')}</h3>
              <p data-sal="slide-down" data-sal-delay="400" data-sal-duration="400">{this.props.t('contact.text')}</p>
              <div data-sal="slide-down" data-sal-delay="600" data-sal-duration="400">
                <Button to="/kontakt">{this.props.t('nav.contact')}</Button>
              </div>
            </StyledContactSection>
          </section>
        </GlobalContainer>
      </GlobalSection>
    )
  }
}
export default withTranslation()(SectionContact)
